<template>
    <form ref="uploadForm" enctype="multipart/form-data">
        <div class="single-input">
            <label for="file">{{ label }}</label>
            <img id="image-preview" class="drop-image" :src="image" alt="Drop image">
            <input class="image-input" id="file" ref="uploadInput" name="file" type="file" @change="uploadFile" accept="image/*">
            <input id="path" name="path" type="hidden" :value="this.path">
        </div>
    </form>
</template>

<script>

export default {
    props: {
        value: {
            type: null,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        path: {
            type: String,
            default: null
        },
        endpoint: {
            type: String,
            default: 'admin/files/upload'
        }
    },
    data() {
        return {
            currentFile: this.value,
        };
    },
    methods: {
        async uploadFile()
        {
            const formData = new window.FormData(this.$refs.uploadForm);

            const response = await this.$api.app.post(this.endpoint, formData, {
            }).catch(error => {
                const errors = response.data?.errors;

                if (errors && errors.file) {
                    this.errors = [...errors.file]
                }
            });

            this.$refs.uploadInput.value = null;
            if(response && response.data) {
                this.currentFile = response.data
            }
        }
    },
    computed: {
        image() {
            if (!this.currentFile) {
                return require('../assets/drop-image.png');
            }

            return this.currentFile;
        }
    },
    watch: {
        value() {
            this.currentFile = this.value;
        },
        currentFile() {
            this.$emit('input', this.currentFile);
        },
    }
}
</script>