import Vue from 'vue';
import * as Veevalidate from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
    Veevalidate.extend(rule, rules[rule]);
});

// Register it globally
Vue.component('ValidationProvider', Veevalidate.ValidationProvider);
Vue.component('ValidationObserver', Veevalidate.ValidationObserver);

export default Veevalidate;
