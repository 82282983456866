import axios from 'axios';
import store from '@/store'

const app = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        Accept: 'application/json',
    },
});

const sportid = axios.create({
    baseURL: process.env.VUE_APP_SPORTID_API,
    headers: {
        Accept: 'application/json',
    },
});

app.interceptors.request.use((config) => {
    const apiToken = store.state.sportid.apiToken;

    if (apiToken) {
        config.headers.Authorization = `Bearer ${apiToken.token}`;
    }

    return config;
}, error => Promise.reject(error));

app.interceptors.response.use((response) => response, (err) => {
    if (axios.isCancel(err)) {
        return Promise.reject(err.response);
    }

    const response = err.response;

    return Promise.reject(response);
});

sportid.interceptors.response.use((response) => response, (err) => {
    if (axios.isCancel(err)) {
        return Promise.reject(err.response);
    }

    const response = err.response;

    return Promise.reject(response);
});

export default {
    app,
    sportid
};
