import CodeScanning from "@/views/CodeScanning";

export default [
    {
        path: '/',
        name: 'web',
        component: () => import('@/layouts/Web'),
        props: true,
        meta: {
            needsAuth: false
        },
        children: [
            {
                path: '/',
                name: 'codescan',
                component: CodeScanning
            },
            {
                path: '/statistics',
                name: 'statistics',
                component: () => import('@/views/Statistics.vue')
            },
            {
                path: '/rankings/heerenveen',
                name: 'rankingsThialf',
                component: () => import('@/views/RankingsThialf.vue')
            },
            {
                path: '/rankings/leeuwarden',
                name: 'rankingsLeeuwarden',
                component: () => import('@/views/RankingsLeeuwarden.vue')
            },
            {
                path: '/diploma/:code',
                name: 'diploma',
                component: () => import('@/views/Diploma.vue'),
                props: true,
            },
            {
                path: '/live',
                name: 'live',
                component: () => import('@/views/live/Live.vue'),
                props: true,
            },
            {
                path: '/live/:event',
                name: 'LiveStatistics',
                component: () => import('@/views/live/LiveStatistics'),
                props: true,
            },
            {
                path: '/live/schema/:city',
                name: 'live page',
                component: () => import('@/views/live/LiveSchema'),
                props: true,
            },
            {
                path: '/unox/:city',
                name: 'unox',
                component: () => import('@/views/unox/unoxlive.vue'),
                props: true,
            },
            {
                path: '/privacypolicy',
                name: 'privacy',
                component: () => import('@/views/boring/Privacy'),
                props: true,
            },
            {
                path: '/generalconditions',
                name: 'conditions',
                component: () => import('@/views/boring/Conditions'),
                props: true,
            }
        ]
    }
];
