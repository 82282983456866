import API from '@/api';

export default {
    namespaced: true,
    state: {
        apiToken: null,
        user: null,
    },
    actions: {
        login({ commit }, payload) {
            return API.sportid.post('/v1/auth/login', payload)
                .then(({ data }) => {
                    commit('setApiToken', data.api_token);
                    commit('setUser', data.user);

                    return data;
                });
        },
        logout({ dispatch, state }) {
            return API.sportid.post('/v1/auth/logout', state.apiToken)
                .then(() => {
                    dispatch('clear');
                });
        },
        tokenRefresh({ commit, dispatch }, apiToken) {
            // Get profile info after user from SportID.
            return API.sportid.post('/v1/token/refresh', apiToken)
                .then(({ data }) => {
                    commit('setUser', data.user);
                    commit('setApiToken', data.api_token);
                })
                .catch(() => {
                    dispatch('clear');
                });
        },
        clear({ commit }) {
            commit('setApiToken', null);
            commit('setUser', null);
        },
    },
    mutations: {
        setApiToken: (state, apiToken) => {
            state.apiToken = apiToken;
            window.localStorage.setItem('api_token', JSON.stringify(apiToken));
        },
        setUser: (state, user) => {
            state.user = user;
            window.localStorage.setItem('user', JSON.stringify(user));
        }
    },
};
