import Vue from 'vue'
import Vuex from 'vuex'
import sportid from './sportid'
import profile from './profile'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        sportid,
        profile,
    },
});
