import auth from './auth'
import admin from './admin'
import web from './web'

const routes = [
    ...auth,
    ...admin,
    ...web
];

export default routes;
