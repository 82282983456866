import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.meta.needsAuth !== true) {
        return next();
    }
    //
    let apiToken = null;
    let user = null;
    let profile = null;
    const authRoutes = ['login'];

    try {
        apiToken = JSON.parse(window.localStorage.getItem('api_token'));
        user = JSON.parse(window.localStorage.getItem('user'));
    } catch (e) {
        apiToken = null;
        user = null;
    }

    if (apiToken) {
        store.commit('sportid/setApiToken', apiToken);
        store.commit('sportid/setUser', user);
    }

    try {
        await store.dispatch('profile/get');
        profile = store.state.profile.data;
    } catch (e) {
        profile = null;
    }

    if (!profile && !authRoutes.includes(to.name)) {
        await store.dispatch('sportid/clear');
        next({name: 'login'})
    }

    if (!apiToken && !authRoutes.includes(to.name)) {
        next({name: 'login'})
    }

    const middleware = to.meta.middleware;
    const context = {to, next, router};

    // Check if there is middleware for route
    if (middleware) {
        middleware[0]({...context});
    }

    if (apiToken && authRoutes.includes(to.name)) {
        next({name: 'admin/events'})
    }

    return next();
});


export default router
