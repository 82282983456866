<template>
  <div class="navigation">
    <div class="container">
      <router-link class="nav-link" to="/statistics" active-class="active">
        <svg width="276" height="330" viewBox="0 0 276 330" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_3670)">
            <path
                d="M137.94 330C150.64 330 275.88 283.38 275.88 164.76V33.42L137.94 0L0 33.42V164.76C0 283.38 125.24 330 137.94 330Z"
                fill="#00CFFF"/>
            <path
                d="M275.88 33.42L137.94 0L12.2 45.07L0 164.76C0 283.38 125.24 330 137.94 330V96.61L263.68 45.06L275.88 33.42Z"
                fill="#00FFFF"/>
            <path
                d="M137.94 315.4C149.52 315.4 263.68 272.91 263.68 164.78V45.07L137.94 14.6L12.2002 45.07V164.79C12.2002 272.91 126.36 315.41 137.94 315.41V315.4Z"
                fill="#003666"/>
            <g style="mix-blend-mode:multiply" opacity="0.35">
              <path d="M137.94 315.4C149.52 315.4 263.68 272.91 263.68 164.78V45.07L137.94 14.6V315.4Z" fill="#003666"/>
            </g>
            <path
                d="M54.9602 165C55.2302 168.89 56.3402 171.57 59.6002 171.57C62.8602 171.57 63.7802 169.44 63.7802 166.75C63.7802 163.47 62.1302 161.54 56.5502 158.68C48.3102 154.43 45.2002 150.84 45.2002 143.87C45.2002 135.76 50.1602 130.44 59.5502 130.44C70.6002 130.44 73.7602 137.34 73.8502 144.62H63.3602C63.1802 141.32 62.5702 138.48 59.4802 138.48C57.0602 138.48 55.9702 140.05 55.9702 142.7C55.9702 145.35 57.0302 146.82 62.1802 149.39C70.9702 153.81 74.7002 157.56 74.7002 165.53C74.7002 173.5 70.1202 179.61 59.4802 179.61C47.9502 179.61 44.4302 173.04 44.2002 164.99H54.9502L54.9602 165Z"
                fill="#00CFFF"/>
            <path d="M84.7802 140.23H75.4302V131.32H104.95V140.23H95.6502V178.73H84.7802V140.23Z" fill="#00CFFF"/>
            <path
                d="M132.97 158.63H118.62V169.93H134.87L133.6 178.73H107.94V131.32H133.63V140.23H118.62V149.77H132.97V158.64V158.63Z"
                fill="#00CFFF"/>
            <path
                d="M137.94 131.32H151.48C163.85 131.32 168.9 138.34 168.9 154.11C168.9 170.91 163.92 178.73 151.09 178.73H137.93V131.32H137.94ZM148.88 170.45H150.9C155.97 170.45 157.45 166.87 157.45 153.94C157.45 143.11 156.11 139.69 150.97 139.69H148.88V170.46V170.45Z"
                fill="#00CFFF"/>
            <path
                d="M197.08 158.63H182.73V169.93H198.98L197.71 178.73H172.05V131.32H197.74V140.23H182.73V149.77H197.08V158.64V158.63Z"
                fill="#00CFFF"/>
            <path
                d="M201.19 178.73V131.32H213.11C220.32 153.66 221.99 159.04 222.48 161.2H222.67C222.2 155.24 222.05 147.42 222.05 140.02V131.31H231.67V178.72H219.93C212.44 154.08 211.04 149.3 210.5 147.25H210.28C210.66 152.95 210.85 161.74 210.85 169.49V178.72H201.19V178.73Z"
                fill="#00CFFF"/>
            <path
                d="M44.21 186.98H52.1C56.62 186.98 59.34 189.64 59.34 194.18C59.34 198.07 57.47 199.79 55.77 200.4C57.97 201.19 60.27 203.22 60.27 207.73C60.27 213.07 57 216.33 51.89 216.33H44.21V186.99V186.98ZM50.96 199.11C54.56 199.11 55.82 197.54 55.82 194.5C55.82 191.63 54.31 189.94 51.33 189.94H47.76V199.11H50.97H50.96ZM47.75 213.4H51.54C54.83 213.4 56.64 211.45 56.64 207.71C56.64 203.97 54.78 202.04 50.93 202.04H47.75V213.4Z"
                fill="#00CFFF"/>
            <path
                d="M84.34 207.37L82.34 216.32H78.73L85.76 186.98H90.44L97.66 216.32H93.84L91.81 207.37H84.34ZM91.1 204.19L88.05 190.36H87.94L85.03 204.19H91.1V204.19Z"
                fill="#00CFFF"/>
            <path d="M117.93 190.18H111.44V186.98H128.02V190.18H121.53V216.32H117.92V190.18H117.93Z" fill="#00CFFF"/>
            <path d="M153.64 190.18H147.15V186.98H163.73V190.18H157.24V216.32H153.63V190.18H153.64Z" fill="#00CFFF"/>
            <path d="M183.65 186.98H187.26V213.18H197.65L197.18 216.32H183.65V186.98V186.98Z" fill="#00CFFF"/>
            <path
                d="M230.57 202.43H220.85V213.15H231.66L231.18 216.31H217.28V186.97H231.05V190.17H220.85V199.24H230.57V202.42V202.43Z"
                fill="#00CFFF"/>
            <path
                d="M117.38 44.86C113.87 48.3 108.45 50.72 101.15 51.32V63.86C103.89 64.22 108.67 64.59 111.7 64.59V115.11H44.21V123.99H130.06V44.86H117.38Z"
                fill="#00CFFF"/>
            <path
                d="M231.67 115.11H171.11V44.86H158.43C154.92 48.3 149.5 50.72 142.2 51.32V63.86C144.94 64.22 149.72 64.59 152.75 64.59V124H231.67V115.12V115.11Z"
                fill="#00CFFF"/>
            <path
                d="M62.19 149.4C57.03 146.82 55.98 145.42 55.98 142.71C55.98 140 56.9 138.75 59.01 137.55C63.61 134.92 73.86 139.37 73.86 144.63C73.77 137.35 70.61 130.45 59.56 130.45C50.17 130.45 45.21 135.77 45.21 143.88C45.21 155.42 68.53 153.87 73.33 158.63C71.54 154.93 67.97 152.31 62.2 149.4H62.19Z"
                fill="#00FFFF"/>
            <path
                d="M59.6 175.11C48.9 175.11 44.49 168.9 44.21 165C44.44 173.05 47.96 179.62 59.49 179.62C69.21 179.62 73.86 174.41 74.59 167.54C73.87 170.41 69.35 175.11 59.6 175.11V175.11Z"
                fill="#00FFFF"/>
            <path d="M84.7802 140.23L75.4302 131.32H104.95V140.23H95.6502L84.7802 178.73V140.23Z" fill="#00FFFF"/>
            <path d="M133.63 140.23L107.94 131.32V140.23H133.63Z" fill="#00FFFF"/>
            <path d="M132.97 149.77L107.94 158.63H132.97V149.77Z" fill="#00FFFF"/>
            <path d="M107.94 169.93V178.73H133.6L107.94 169.93Z" fill="#00FFFF"/>
            <path d="M197.74 131.32H172.05L197.74 140.23V131.32Z" fill="#00FFFF"/>
            <path d="M197.08 149.77H172.05V158.63L197.08 149.77Z" fill="#00FFFF"/>
            <path d="M172.05 169.93L197.71 178.73L198.98 169.93H172.05Z" fill="#00FFFF"/>
            <path
                d="M137.94 131.32H151.48C163.85 131.32 168.9 138.34 168.9 154.11C168.9 170.91 161.7 170.45 148.88 170.45L137.94 131.32V131.32ZM148.88 170.45H150.9C155.97 170.45 157.45 166.87 157.45 153.94C157.45 143.11 156.11 139.69 150.97 139.69H148.88V170.46V170.45Z"
                fill="#00FFFF"/>
            <path
                d="M201.19 178.73V131.32C201.19 131.32 221.99 159.04 222.48 161.2L231.67 178.73L222.05 131.32H231.67V178.73H219.93C212.44 154.09 211.04 149.31 210.5 147.26H210.28L201.19 178.73V178.73Z"
                fill="#00FFFF"/>
            <path
                d="M47.76 189.94L52.1 186.98C56.62 186.98 59.34 189.64 59.34 194.18C59.34 198.07 57.08 199.12 47.76 199.12C49.96 199.91 60.28 197.92 60.28 207.73C60.28 213.07 52.88 213.41 47.76 213.41L44.21 216.33L47.76 189.95V189.94ZM50.97 199.11C54.57 199.11 55.83 197.54 55.83 194.5C55.83 191.63 54.32 189.94 51.34 189.94H47.77V199.11H50.98H50.97ZM47.76 213.4H51.55C54.84 213.4 56.65 211.45 56.65 207.71C56.65 203.97 54.79 202.04 50.94 202.04H47.76V213.4Z"
                fill="#00FFFF"/>
            <path
                d="M88.0401 190.36H87.9301L85.0201 204.19L82.3301 216.32L85.7501 186.98H90.4301L97.6501 216.32L88.0401 190.37V190.36Z"
                fill="#00FFFF"/>
            <path d="M117.93 190.18L111.44 186.98H128.02V190.18H121.53V216.32L117.92 190.18H117.93Z" fill="#00FFFF"/>
            <path d="M157.25 190.18H147.15V186.98L163.73 190.18H157.24V216.32H153.63L157.24 190.18H157.25Z"
                  fill="#00FFFF"/>
            <path d="M183.65 186.98L187.26 213.18H197.65L183.65 216.32V186.98V186.98Z" fill="#00FFFF"/>
            <path d="M231.05 186.98L217.29 190.18H231.05V186.98Z" fill="#00FFFF"/>
            <path d="M217.29 213.15L231.19 216.32L231.67 213.15H217.29Z" fill="#00FFFF"/>
            <path d="M230.57 199.25H217.29L230.57 202.43V199.25Z" fill="#00FFFF"/>
            <path d="M44.21 223.17V232.06H121.18V270.36H130.06V223.17H44.21Z" fill="#00CFFF"/>
            <path d="M231.67 232.06V223.17H145.82V270.36H154.7V232.06H231.67Z" fill="#00CFFF"/>
            <path d="M44.21 223.17L121.18 232.06V270.36L130.06 223.17H44.21Z" fill="#00FFFF"/>
            <path d="M231.67 232.06V223.17L154.7 232.06L145.82 270.36H154.7V232.06H231.67Z" fill="#00FFFF"/>
            <path
                d="M117.38 44.86C113.87 48.3 108.45 50.72 101.15 51.32V63.86C103.89 64.22 108.67 64.59 111.7 64.59V115.11H44.21V123.99L111.7 115.11L130.06 44.86H117.38V44.86Z"
                fill="#00FFFF"/>
            <path
                d="M231.67 115.11H171.11V44.86H158.43C154.92 48.3 149.5 50.72 142.2 51.32V63.86L171.11 44.87L152.75 124.01L231.67 115.13V115.11Z"
                fill="#00FFFF"/>
          </g>
          <defs>
            <clipPath id="clip0_1_3670">
              <rect width="275.88" height="330" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </router-link>
      <div class="nav-links">
        <router-link class="nav-link" to="/statistics" active-class="active">Mijn statistieken</router-link>
        <div class="nav-dropdown">
          <button class="nav-button">Ranglijsten</button>
          <div class="dropdown-content">
            <router-link class="nav-link live-link" to="/rankings/leeuwarden" active-class="active">Leeuwarden
            </router-link>
            <router-link class="nav-link live-link" to="/rankings/heerenveen" active-class="active">Thialf</router-link>
          </div>
        </div>

        <router-link class="nav-link" to="/live" active-class="active">
          Live
        </router-link>
      </div>
      <div v-if="!showSimple" class="go-back" @click="resetStorage()">
        <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
          <path
              d="M14 38v-3h14.45q3.5 0 6.025-2.325Q37 30.35 37 26.9t-2.525-5.775Q31.95 18.8 28.45 18.8H13.7l5.7 5.7-2.1 2.1L8 17.3 17.3 8l2.1 2.1-5.7 5.7h14.7q4.75 0 8.175 3.2Q40 22.2 40 26.9t-3.425 7.9Q33.15 38 28.4 38Z"/>
        </svg>
        <p>Voer andere code in</p>
      </div>

      <router-link to="/admin/events">
        <button type="button" class="nav-link login-button">
          Dashboard
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "navbar",
  props: ['showSimple'],
  methods: {
    resetStorage() {
      localStorage.removeItem('code');
      window.location.href = '/';
    }
  },
  mounted() {
    this.showSimple = this.$props.showSimple
  }
}
</script>

<style scoped lang="scss">
.nav-dropdown {
  @media (max-width: 1000px) {
    display: none;
  }
  z-index: 10;

  &:hover {
    .nav-button {
      background-color: rgba(225, 225, 225, 0.27);
    }

    .dropdown-content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border-radius: 7px;
      padding-top: 5px;
    }
  }

  .nav-button {
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.15s;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    a {
      color: #003666 !important;
      padding: 5px 10px;
      background-color: #f9f9f9 !important;

      &:hover {
        background-color: #eaeaea !important;
      }
    }
  }
}

.live-link {
  @media (max-width: 600px) {
    display: none;
  }
}

.navigation {
  background-color: #003666;

  .container {
    padding: 10px 32px;
    position: relative;
    @media (max-width: 700px) {
      padding: 10px;
    }
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;

    .nav-links {
      margin: auto;
      display: flex;
      gap: 20px;
      @media (max-width: 700px) {
        position: absolute;
        right: 10px;
        gap: 10px;
      }
      @media (max-width: 340px) {
        gap: 2px;
      }

      .nav-link {
        color: white;
        font-weight: 500;
        padding: 10px 15px;
        border-radius: 5px;
        text-decoration: none;
        transition: .15s;

        &:not(.active):hover {
          background-color: rgba(215, 215, 215, 0.34);
        }
      }

      .active {
        background-color: white;
        color: #091E42;
      }
    }

    svg {
      width: 50px;
      height: 55px;
      @media (max-width: 350px) {
        width: 30px;
      }
    }


  }

}

.go-back {
  position: absolute;
  left: 0;
  color: #A5ADBA;
  display: flex;
  top: 105px;
  cursor: pointer;
  align-items: center;
  transition: .3s;
  height: 20px;

  &:hover {
    svg {
      fill: #667085;
    }

    p {
      color: #667085;
    }
  }

  svg {
    transform: scale(.45);
    fill: #A5ADBA;
  }

  p {
    margin-left: -7px;
    font-size: 15px;
  }
}
</style>
