export default [
    {
        path: '/admin',
        name: 'admin',
        component: () => import('@/layouts/Admin'),
        props: true,
        meta: {
            needsAuth: true
        },
        children: [
            {
                path: '',
                redirect: 'events',
                meta: {
                    needsAuth: true
                }
            },
            {
                path: 'events',
                name: 'events.index',
                component: () => import('@/views/admin/events/Events'),
                props: true,
                meta: {
                    needsAuth: true
                }
            },
            {
                path: 'events/:event',
                name: 'events.show',
                component: () => import('@/views/admin/events/Event'),
                props: true,
                meta: {
                    needsAuth: true
                }
            },
            {
                path: 'group/:group',
                name: 'group.show',
                component: () => import('@/views/admin/groups/Group'),
                props: true,
                meta: {
                    needsAuth: true
                }
            },
        ]
    },
]
