import API from '@/api';

export default {
    namespaced: true,
    state: {
        data: null,
        loading: false,
    },
    mutations: {
        set(store, payload) {
            store.data = payload;
        },
        loading(store, payload) {
            store.loading = payload;
        },
    },
    actions: {
        async get(store, param) {
            if (!store.state.data) {
                return store.dispatch('update', param);
            }

            return store.state.data;
        },
        async update(store) {
            store.commit('loading', true);

            const response = await API.app.get('admin/profile').catch((err) => {
                throw err;
            });

            store.commit('loading', false);
            if (response && response.data) {
                store.commit('set', response.data);
                return response.data;
            }

            store.commit('set', null);
            return false;
        },
        clear({ commit }) {
            commit('set', null);
        },
    },
};
